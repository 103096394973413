var exports = {};
exports = SafeParseTuple;

function SafeParseTuple(obj, reviver) {
  var json;
  var error = null;

  try {
    json = JSON.parse(obj, reviver);
  } catch (err) {
    error = err;
  }

  return [error, json];
}

export default exports;